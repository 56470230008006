<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.TAG_MANAGEMENT') }} - {{ $t('MANAGEMENT.CREATE_EDIT_CATEGORY') }}
    </p>
    <div class="sm-content">
      <div class="d-flex justify-content-between form-block px7">
        <div class="category-name">
          <label>{{ $t('MANAGEMENT.CATEGORY_NAME') }}</label>
          <div class="icon-input">
            <input type="text" class="input" />
            <img src="/assets/img/icons/search-light.svg" alt="" />
          </div>
        </div>
        <div class="status">
          <label>{{ $t('REPORT_SALES.STATUS') }}</label>
          <vue-select
            :options="test.options"
            class="input cus-select"
            v-model="test.selected"
          ></vue-select>
        </div>
        <div class="color-form-block">
          <label>{{ $t('MANAGEMENT.BACKGROUND_COLOR_PICKER') }}</label>
          <ColorPicker :color="bg_color" v-model="bg_color" />
        </div>
        <div class="color-form-block">
          <label>{{ $t('MANAGEMENT.CATEGORY_TEXT_COLOR') }}</label>
          <ColorPicker :color="txt_color" v-model="txt_color" />
        </div>
      </div>  
      <div class="d-flex justify-content-between m-t-30">
        <div class="d-flex w-100">
          <button class="primary-btn primary-btn_sm">
            {{ $t("COMMON.SAVE") }}
          </button>
          <button class="primary-btn primary-btn_sm mx10 bg-red2">
            {{ $t("SUPPLIER_PAYMENT.CANCELATION") }}
          </button>
        </div>
        <button class="primary-btn primary-btn_sm bg-yellow">
          {{ $t("COMMON.NEW") }}
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import ColorPicker from '../../../components/ColorPicker'
export default {
  name: 'SCTagEditCategory',
  components: {
    ColorPicker
  },
  data() {
    return {
      test: {
        options: ['test'],
        selected: 'test',
      },
      bg_color: '#FF0000',
      txt_color: '#FF0000'
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  .category-name {
  width: 30%;
  }
  .status {
    width: 20%;
  }
  .color-form-block {
    width: 23%;
  }
}

@media screen and (max-width: 767px) {
  .form-block {
    flex-wrap: wrap;
    .category-name,
    .status,
    .color-form-block {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
</style>